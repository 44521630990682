var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-layout-login-wraper" },
    [
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _c(
              "a-card",
              { attrs: { loading: _vm.checking, bordered: false } },
              [
                _vm.displayMap.showRegister
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              {
                                attrs: {
                                  xs: { span: 24 },
                                  md: { span: 17, offset: 7 }
                                }
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "17px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("Account information")) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("profile-form", {
                          attrs: {
                            type: "register",
                            profile: _vm.initialProfile,
                            formItemLayout: _vm.formItemLayout,
                            buttonItemLayout: _vm.buttonItemLayout
                          },
                          on: { register: _vm.register }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.displayMap.showConfirm
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c("h5", { staticStyle: { "margin-bottom": "24px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("Your registration has been received.")
                              ) +
                              " "
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "We will approve and send your account password to email "
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.email))]
                          )
                        ]),
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/user/login" } } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "32px" } },
                              [_vm._v(_vm._s(_vm.$t("Go to Sign in")))]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.displayMap.showChecking || _vm.displayMap.showError
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("The token is expired or does not exist")
                            )
                          )
                        ]),
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/user/login" } } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "32px" } },
                              [_vm._v(_vm._s(_vm.$t("Go to Sign in")))]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }