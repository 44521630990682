//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileForm from '@/components/ProfileForm/Index';
export default {
  components: {
    ProfileForm
  },
  data() {
    return {
      email: null,
      checking: true,
      loading: false,
      displayMap: {}
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { xs: { span: 8 }, md: { span: 7 }, lg: { span: 6 } },
        wrapperCol: { xs: { span: 16 }, md: { span: 17 }, lg: { span: 18 } }
      };
    },
    buttonItemLayout() {
      return {
        wrapperCol: {
          xs: { span: 24 },
          md: { span: 17, offset: 7 },
          lg: { span: 18, offset: 6 }
        }
      };
    },
    initialProfile() {
      return {
        email: this.email,
        contact_phone: ''
      };
    }
  },
  async mounted() {
    this.updateDisplayMap('checking');
    try {
      const { token } = this.$route.query;
      const data = await this.$s.api.company.verifyRequestToken(token);
      const { email } = data;
      this.email = email;
      this.updateDisplayMap('register');
    } catch (error) {
      console.log(error);
      this.updateDisplayMap('error');
    }
    this.checking = false;
  },
  methods: {
    async register(value) {
      try {
        this.loading = true;
        const { token } = this.$route.query;
        await this.$s.api.company.register(token, value);
        this.registerSuccess();
      } catch (error) {
        console.log(error);
        this.$message.error(this.$t(error.message));
      } finally {
        this.loading = false;
      }
    },

    registerSuccess() {
      this.updateDisplayMap('comfirm');
    },

    updateDisplayMap(state) {
      this.displayMap = {
        showRegister: state === 'register',
        showConfirm: state === 'comfirm',
        showChecking: state === 'checking',
        showError: state === 'error'
      };
    }
  }
};
